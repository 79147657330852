import { Notifier } from '@airbrake/browser';
import { PUBLIC_NODE_ENV } from '$env/static/public';

const airbrake = new Notifier({
  environment: PUBLIC_NODE_ENV,
  projectId: 515505,
  projectKey: 'ac3ffcd7df87b7f7118d05c0b31c8a54',
});

export default airbrake;
